import React from "react";
import "./skeletons.css";

const UpcomingEventsSkeleton = () => {
  return (
    <section className="upcomingEventsSlides">
      <div className="header">
        <div className="skeleton-title"></div>
      </div>
      <div className="skeleton-slide">
        <div className="skeleton-image"></div>
        <div className="skeleton-content">
          <div className="skeleton-title"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-button"></div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEventsSkeleton;
