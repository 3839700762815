import React from "react";

import { ScrollToTop } from "../../components";
import HamperForm from "../../components/forms/hamperForm";

const Register = () => {
  return (
    <>
      <section className="rsvp">
        <div className="container">
          <div className="header">
            <h1>Register</h1>
            <h3 className="left-align">
              Black, African and Caribbean New Mom Hamper Program
            </h3>
            <article className="left-align">
              Eligibility Service targeted to:
              <ul>
                <li>
                  • Black moms who are residents of Edmonton and surrounding
                  area
                </li>
                <li>• Black moms in need of baby/ mom supplies</li>
                <li>• Babies must be 12months and under </li>
              </ul>
              <p>
                Please note that the hamper program is not targeted at expectant
                mothers.
              </p>
            </article>
          </div>
          <div className="formContent">
            <HamperForm />
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Register;
