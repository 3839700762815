// JobViewModal.js

import React from "react";
import "./modal.css";
import { BsEnvelopeAtFill, BsFillClockFill } from "react-icons/bs";
import { HiUser } from "react-icons/hi2";
import { MdLocationPin, MdWorkspacePremium, MdWork } from "react-icons/md";
import { FaPhoneAlt, FaGlobe } from "react-icons/fa";
import defaultPic from "../../assets/images/default-avatar.png";
import SelectedJob from "../SelectedJob";

const JobViewModal = ({ showJobViewModal, closeJobViewModal, selectedJob }) => {
  const modalClassName = showJobViewModal
    ? "modalContainer job-view active"
    : "modalContainer job-view";

  return (
    <>
      {showJobViewModal && (
        <div
          className="modalBackground jobViewModalBkg"
          onClick={() => closeJobViewModal(false)}
        >
          <div className={modalClassName} onClick={(e) => e.stopPropagation()}>
            <div className="closeBtn">
              <button onClick={() => closeJobViewModal(false)}>X</button>
            </div>

            <div className="modalContent center">
              <SelectedJob selectedJob={selectedJob} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobViewModal;
