import React from "react";
import { Helmet } from "react-helmet";
import { donateContent } from "../../constants/data";

const DonateMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const donateUrl = `${baseUrl}/donate`;

  const metadata = {
    title: "Donate | Mesh of Mothers",
    description: donateContent.supportText,
    keywords:
      "donate, Mesh of Mothers, African Canadian mothers, support, community, maternal wellbeing, payment methods",
    og: {
      title: "Support Mesh of Mothers - Donate Now",
      description:
        "Your donation to Mesh of Mothers helps support African Canadian mothers and women in Edmonton. Every contribution counts!",
      image: `${baseUrl}/moms-logo.png`,
      url: donateUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Empower Mothers - Donate to Mesh of Mothers",
      description:
        "Help us create a positive environment for African Canadian mothers. Your donation to Mesh of Mothers can make a difference.",
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "DonateAction",
      name: "Donate to Mesh of Mothers",
      description: donateContent.supportText,
      url: donateUrl,
      actionStatus: "PotentialActionStatus",
      agent: {
        "@type": "NGO",
        name: "Mesh of Mothers Foundation Inc",
        url: baseUrl,
      },
      recipient: {
        "@type": "NGO",
        name: "Mesh of Mothers Foundation Inc",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default DonateMetadata;
