import React from "react";
import CountdownTimer from "./CountdownTimer";
import "./countdown.css";

const Countdown = () => {
  // Calculate the target date for the launch (e.g., Friday, 4 PM)
  // Update the date, month, and year as needed
  // Original target date and time in UTC
  const targetDateUTC = new Date("2023-07-21T16:00:00").getTime();

  // GMT-6 is UTC-6, so we need to subtract 6 hours in milliseconds to convert to CST
  const cstOffsetMilliseconds = 6 * 60 * 60 * 1000;
  const targetDateCST = targetDateUTC - cstOffsetMilliseconds;

  // A new Date object representing the target date and time in CST
  const targetDateCSTObject = new Date(targetDateCST);

  return (
    <div className="timer">
      <CountdownTimer targetDate={targetDateCSTObject} />
    </div>
  );
};

export default Countdown;
