import { useEffect, useState } from "react";

const useCountDown = (targetDate) => {
  const storedTargetDate = localStorage.getItem("targetDate");
  const initialCountDownDate = storedTargetDate
    ? new Date(parseInt(storedTargetDate)).getTime()
    : new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    initialCountDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(initialCountDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [initialCountDownDate]);

  // Store the target date in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("targetDate", initialCountDownDate);
  }, [initialCountDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export default useCountDown;
