import React from "react";
import "./completion.css";

const DonationSuccess = () => {
  return (
    <div className="completion pt-6">
      <div className="container">
        <h3>Thank you for your generous donation! 🎉</h3>
        <p>
          Your support makes a significant difference, and we’re incredibly
          grateful for your contribution. <br /> Together, we’re
          making an impact.
        </p>
      </div>
    </div>
  );
};

export default DonationSuccess;
