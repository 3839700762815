import React from "react";
import { Helmet } from "react-helmet";
import { aboutContent } from "../../constants/data";

const AboutUsMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const aboutUsUrl = `${baseUrl}/about-us`;

  const metadata = {
    title: "About Us | Mesh of Mothers",
    description: aboutContent.helloContent,
    keywords:
      "Mesh of Mothers, African Canadian mothers, women, Edmonton, maternal wellbeing, community, support",
    og: {
      title: "About Mesh of Mothers - Empowering African Canadian Mothers",
      description: aboutContent.mission,
      image: `${baseUrl}/moms-logo.png`,
      url: aboutUsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Mesh of Mothers: Our Mission and Vision",
      description: aboutContent.vision,
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: "Mesh of Mothers Foundation",
      url: baseUrl,
      logo: `${baseUrl}/moms-logo.png`,
      description: aboutContent.helloContent,
      foundingDate: "2021",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Edmonton",
        addressRegion: "Alberta",
        addressCountry: "Canada",
      },
      sameAs: [
        "https://www.facebook.com/meshofmothers",
        "https://tiktok.com/meshofmothers",
        "https://www.instagram.com/meshofmothers",
        "https://www.youtube.com/@meshofmothers",
      ],
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default AboutUsMetadata;
