import React, { useState } from "react";

const JobFilterTabs = ({ categories, jobs, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(categories[0]);

  const handleTabClick = (category) => {
    setActiveTab(category);
    onTabChange(category);
  };

  return (
    <div className="job-filter-tabs-container">
      <div className="job-filter-tabs">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`filter-tab ${activeTab === category ? "active" : ""}`}
            onClick={() => handleTabClick(category)}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobFilterTabs;
