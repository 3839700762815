import React from "react";
import "./skeletons.css";

const AboutUsSkeleton = () => {
  return (
    <>
      {/* Hello Section Skeleton */}
      <section className="helloSection pt-6">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="skeleton-image"></div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-1">
              <div className="skeleton-title"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Logo Section Skeleton */}
      <section className="ourLogo mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="skeleton-title"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
              <div className="skeleton-text"></div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="skeleton-image"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Attributes Section Skeleton */}
      <section className="attributes mt-5">
        <div className="container">
          {/* Mission */}
          <div className="row mb-4">
            <div
              className="col-lg-10 col-12 p-4"
              style={{ backgroundColor: "var(--blue-dark-variant)" }}
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="skeleton-image"></div>
                </div>
                <div className="col-12 col-lg-6">
                  <div
                    className="skeleton-title"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* Vision */}
          <div className="row mb-4">
            <div
              className="col-lg-10 col-12 offset-lg-2 p-4"
              style={{ backgroundColor: "var(--orange-red)" }}
            >
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div
                    className="skeleton-title"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="skeleton-image"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Values */}
          <div className="row">
            <div
              className="col-lg-10 col-12 p-4"
              style={{ backgroundColor: "var(--brown)" }}
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="skeleton-image"></div>
                </div>
                <div className="col-12 col-lg-6">
                  <div
                    className="skeleton-title"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="skeleton-text"
                      style={{ backgroundColor: "#ffffff40" }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Girls Group Section Skeleton */}
      <section className="girlGroup mt-5">
        <div className="container">
          <div className="skeleton-image" style={{ height: "300px" }}></div>
        </div>
      </section>

      {/* Architects Section Skeleton */}
      <section
        className="architects pt-4 mt-5"
        style={{ backgroundColor: "var(--blue-dark)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 py-5">
              <div
                className="skeleton-title mx-auto"
                style={{ backgroundColor: "#ffffff40" }}
              ></div>
              <div
                className="skeleton-text mx-auto w-75"
                style={{ backgroundColor: "#ffffff40" }}
              ></div>
            </div>
          </div>

          {[...Array(3)].map((_, index) => (
            <div key={index} className="row px-0 px-lg-5 mb-5">
              <div className="col-12">
                <div className="row align-items-end px-lg-6">
                  <div
                    className={`col-lg-4 col-md-6 col-12 ${
                      index % 2 !== 0 ? "order-2" : ""
                    }`}
                  >
                    <div
                      className="skeleton-image"
                      style={{ height: "250px" }}
                    ></div>
                  </div>
                  <div
                    className={`col-lg-8 col-md-6 col-12 ${
                      index % 2 !== 0 ? "order-1" : ""
                    }`}
                  >
                    <div
                      className="skeleton-title"
                      style={{ backgroundColor: "#ffffff40" }}
                    ></div>
                    <div
                      className="skeleton-text"
                      style={{ backgroundColor: "#ffffff40" }}
                    ></div>
                    <div
                      className="skeleton-text"
                      style={{ backgroundColor: "#ffffff40" }}
                    ></div>
                    <div
                      className="skeleton-text"
                      style={{ backgroundColor: "#ffffff40" }}
                    ></div>
                  </div>
                </div>
                <div className="mt-4">
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                  <div
                    className="skeleton-text"
                    style={{ backgroundColor: "#ffffff40" }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AboutUsSkeleton;
