import React from "react";
import { Helmet } from "react-helmet";
import { categories, jobs } from "./data";

const ResourceMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const resourceUrl = `${baseUrl}/resources`;

  const uniqueProfessions = [...new Set(jobs.map((job) => job.profession))];
  const locations = [
    ...new Set(
      jobs.map((job) => job.address.split(",")[1]?.trim()).filter(Boolean)
    ),
  ];

  const metadata = {
    title: "Resources | Mesh of Mothers",
    description:
      "Access a comprehensive directory of professionals supporting African Canadian mothers. Find midwives, lactation consultants, doulas, mental health therapists, and more across Canada.",
    keywords: `Mesh of Mothers, resources, ${categories.join(
      ", "
    )}, ${locations.join(", ")}, African Canadian mothers, maternal support`,
    og: {
      title: "Mesh of Mothers Resources - Support for African Canadian Mothers",
      description:
        "Discover a network of professionals dedicated to supporting African Canadian mothers. From pregnancy to postpartum care, find the help you need.",
      image: `${baseUrl}/moms-logo.png`,
      url: resourceUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Maternal Support Resources - Mesh of Mothers",
      description:
        "Connect with professionals who understand the unique needs of African Canadian mothers. Explore our comprehensive resource directory.",
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "ItemList",
      name: "Mesh of Mothers Resource Directory",
      description:
        "A comprehensive list of professionals providing support to African Canadian mothers across Canada.",
      url: resourceUrl,
      itemListElement: jobs.map((job, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@type": "Person",
          name: `${job.firstName} ${job.lastName}`,
          jobTitle: job.profession,
          worksFor: {
            "@type": "Organization",
            name: job.company,
          },
          url: job.website,
          telephone: job.phoneNumber,
          email: job.email,
          address: {
            "@type": "PostalAddress",
            addressLocality: job.address.split(",")[1]?.trim(),
            addressRegion: job.address.split(",")[2]?.trim(),
            postalCode: job.postal_zip_code,
          },
        },
      })),
      provider: {
        "@type": "Organization",
        name: "Mesh of Mothers Foundation Inc",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default ResourceMetadata;
