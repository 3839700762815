import React, { useState } from "react";
// import useExternalScripts from "../../hooks/useExternalScripts";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcApplePay,
  FaGooglePay,
  FaCcDiscover,
} from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import "./payment.css";

const DonatePayment = () => {
  //   useExternalScripts(
  //     "https://zeffy-scripts.s3.ca-central-1.amazonaws.com/embed-form-script.min.js"
  //   );

  return (
    <>
      <div className="paymentIcons">
        <FaCcVisa className="p-icon" />
        <FaCcMastercard className="p-icon" />
        <FaCcDiscover className="p-icon" />
        <FaCcApplePay className="p-icon" />
        <FaGooglePay className="p-icon" />
        <SiAmericanexpress className="p-icon" />
      </div>
      {/* <div className="p-btnHolder">
        <Link
          target="_blank"
          to={"https://buy.stripe.com/aEUaIKggr2b3ci4fYY"}
          className="btnPrimary"
        >
          click to make donation
        </Link>
      </div> */}
    </>
  );
};

export default DonatePayment;
