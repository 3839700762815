import React, { useState, useEffect } from "react";

const ProgressiveImage = ({
  lowQualitySrc,
  highQualitySrc,
  alt,
  className,
}) => {
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [highQualitySrc]);

  return (
    <img
      src={src}
      alt={alt}
      className={`${className} ${src === lowQualitySrc ? "loading" : "loaded"}`}
      style={{
        filter: src === lowQualitySrc ? "blur(10px)" : "none",
        transition: "filter 0.3s ease-out",
      }}
    />
  );
};

export default ProgressiveImage;
