import React from "react";
import { Helmet } from "react-helmet";
import { membershipContent } from "../../constants/data";

const MembershipPageMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const membershipUrl = `${baseUrl}/membership`;

  const metadata = {
    title: "Membership | Mesh of Mothers",
    description: `${
      membershipContent.familySection.subTitle
    } ${membershipContent.familySection.textContent.slice(0, 150)}...`,
    keywords:
      "Mesh of Mothers, membership, volunteer, African Canadian mothers, community support, motherhood sanctuary, sisterhood",
    og: {
      title: `Join Mesh of Mothers - ${membershipContent.heroText}`,
      description: membershipContent.familySection.textContent,
      image: `${baseUrl}/moms-logo.png`,
      url: membershipUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Mesh of Mothers Membership - Discover Your Motherhood Sanctuary",
      description: membershipContent.volunteerSection.textContent,
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Mesh of Mothers Foundation Inc",
      url: baseUrl,
      logo: `${baseUrl}/moms-logo.png`,
      sameAs: [
        "https://www.facebook.com/meshofmothers",
        "https://tiktok.com/meshofmothers",
        "https://www.instagram.com/meshofmothers",
        "https://www.youtube.com/@meshofmothers",
      ],
      contactPoint: {
        "@type": "ContactPoint",
        contactType: "customer support",
      },
      volunteer: {
        "@type": "VolunteerInfo",
        description: membershipContent.volunteerSection.textContent,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default MembershipPageMetadata;
