import React from "react";
import { Helmet } from "react-helmet";
import { programsContent } from "../../constants/data";

const ProgramsMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const programsUrl = `${baseUrl}/programs`;

  const metadata = {
    title: "Our Programs | Mesh of Mothers",
    description:
      "Explore Mesh of Mothers' programs designed to support and empower African Canadian mothers. From psycho-education to community initiatives, discover how we're making a difference.",
    keywords: `Mesh of Mothers programs, ${programsContent.list
      .map((program) => program.title)
      .join(", ")}, African Canadian mothers, maternal well-being`,
    og: {
      title: "Mesh of Mothers Programs - Empowering African Canadian Mothers",
      description:
        "Learn about Mesh of Mothers' range of programs designed to support and empower African Canadian mothers. Join our community and make a difference.",
      image: `${baseUrl}/moms-logo.png`,
      url: programsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Discover Mesh of Mothers' Support Programs",
      description:
        "From psycho-education to maternal mentorship, explore how Mesh of Mothers is enhancing the lives of African Canadian mothers through our diverse programs.",
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "ItemList",
      name: "Mesh of Mothers Support Programs",
      description:
        "A comprehensive list of programs offered by Mesh of Mothers to support African Canadian mothers.",
      url: programsUrl,
      itemListElement: programsContent.list.map((program, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: program.title,
        description: program.description,
        url: `${programsUrl}#${program.title
          .toLowerCase()
          .replace(/\s+/g, "-")}`,
      })),
      provider: {
        "@type": "NGO",
        name: "Mesh of Mothers Foundation Inc",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default ProgramsMetadata;
