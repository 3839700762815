import React from "react";
import "./skeletons.css";

const MembershipSkeleton = () => {
  return (
    <>
      <section className="membershipHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <div
                className="skeleton-text"
                style={{ width: "60%", margin: "0 auto" }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      <section className="family">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="content">
                <div className="header">
                  <div className="skeleton-title"></div>
                </div>
                <div className="txtContent">
                  <article>
                    <div
                      className="skeleton-text"
                      style={{ width: "40%" }}
                    ></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                  </article>
                </div>
                <div className="btnHolder">
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imgWrapper">
                <div className="skeleton-image"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="volunteer">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imgWrapper">
                <div className="skeleton-image"></div>
              </div>
            </div>
            <div className="ver">
              <div className="content">
                <div className="header">
                  <div className="skeleton-title"></div>
                </div>
                <div className="txtContent">
                  <article>
                    <div
                      className="skeleton-text"
                      style={{ width: "40%" }}
                    ></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                  </article>
                </div>
                <div className="btnHolder">
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MembershipSkeleton;
