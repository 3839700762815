export const navigationLinks = [
  {
    id: "1",
    name: "about us",
    url: "/about-us",
  },
  {
    id: "2",
    name: "programs",
    url: "/programs",
  },
  {
    id: "3",
    name: "events",
    url: "/events",
  },
  // {
  //   id: "4",
  //   name: "how to donate",
  //   url: "/donate",
  // },
  // {
  //   id: "5",
  //   name: "BIPOC Form",
  //   url: "https://form.jotform.com/232890162361050",
  // },

  {
    id: "6",
    name: "membership",
    url: "/membership",
    submenu: [
      {
        id: "5",
        name: "BIPOC Form",
        url: "https://form.jotform.com/232890162361050",
      },
    ],
  },
  {
    id: "7",
    name: "contact us",
    url: "/contact-us",
  },
];

export const footerMenuLinks = {
  supportLinks: {
    title: "support",
    links: [
      {
        id: "1",
        name: "privacy policy",
        url: "/privacy-policy",
      },
      {
        id: "2",
        name: "cookie policy",
        url: "/cookie-policy",
      },
      {
        id: "3",
        name: "terms and conditions",
        url: "/terms-and-conditions",
      },
    ],
  },
  menuLinks: {
    title: "menu",
    links: [
      {
        id: "1",
        name: "about us",
        url: "/about-us",
      },
      {
        id: "2",
        name: "events ",
        url: "/events",
      },
      {
        id: "3",
        name: "BIPOC Form",
        url: "https://form.jotform.com/232890162361050",
      },
      {
        id: "4",
        name: "membership",
        url: "/membership",
      },
      {
        id: "5",
        name: "contact us",
        url: "/contact-us",
      },
      {
        id: "6",
        name: "how to donate",
        url: "/donate",
      },
      {
        id: "7",
        name: "Resources",
        url: "/resources",
      },
    ],
  },
};
