import React, { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./event-detail.css";
import { Countdown, ScrollToTop } from "../../components";
import { events } from "../../constants/data";
import EventDetailSkeleton from "../../components/skeletons/EventDetailSkeleton";
import ProgressiveImage from "../../components/ProgressiveImage";
import EventDetailHelmet from "../../components/generateMetadata/EventDetailHelmet";

const Gallery = lazy(() => import("../../components/gallery"));
const YoutubeFrame = lazy(() => import("../../components/youtubeFrame"));

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const EventDetail = () => {
  const { eventSlug } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [ourEvents, setOurEvents] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const foundEvent = events.find(
        (event) => convertToSlug(event.title) === eventSlug
      );
      if (foundEvent) {
        setOurEvents(foundEvent);
        setIsLoading(false);
      } else {
        // If no event is found, navigate to a 404 page or show an error
        navigate("/not-found", { replace: true });
      }
    };

    fetchEvent();
  }, [eventSlug, navigate]);

  if (isLoading) {
    return (
      <>
        <EventDetailSkeleton />
        <Countdown />
        <ScrollToTop />
      </>
    );
  }

  if (!ourEvents) {
    return <div>Event not found</div>;
  }

  return (
    <>
      <EventDetailHelmet event={ourEvents} />
      <section className="eventDetailSection">
        <div className="container">
          <div className="eventHeader hor">
            <div className="left ver flex">
              <div className="eventTitle">
                <h1>{ourEvents.title}</h1>
              </div>
            </div>
            <div className="right ver flex">
              <div className="imgWrapper">
                <ProgressiveImage
                  lowQualitySrc={ourEvents.lowQualityImage}
                  highQualitySrc={ourEvents.image}
                  alt={ourEvents.title}
                />
              </div>
            </div>
          </div>
          <div className="eventDetail">
            <p>{ourEvents.details}</p>
          </div>
          <div className="eventVideo">
            <Suspense fallback={<div>Loading video...</div>}>
              <YoutubeFrame link={ourEvents.youtubeURL} />
            </Suspense>
          </div>
          <div className="eventPictures">
            <div className="header">
              <h3>Gallery</h3>
            </div>
            <Suspense fallback={<div>Loading gallery...</div>}>
              <Gallery galleryImages={ourEvents.pictures} />
            </Suspense>
          </div>
        </div>
      </section>
      <Countdown />
      <ScrollToTop />
    </>
  );
};

export default EventDetail;
