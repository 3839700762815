import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Notification } from "..";
import "./forms.css";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  province: Yup.string().required("Province is required"),
  postalCode: Yup.string().required("Postal code is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  transportation: Yup.boolean(),
  breastfeeding: Yup.boolean(),
});

const HamperForm = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("info");

  const handleNotification = (message, type) => {
    setShowNotification(true);
    setNotificationMessage(message);
    setNotificationType(type);

    // hide the notification after  5 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <>
      <Formik
        initialValues={{
          fullName: "",
          address: "",
          city: "",
          province: "",
          postalCode: "",
          phoneNumber: "",
          emailAddress: "",
          transportation: false,
          breastfeeding: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/register`, values)
            .then((response) => {
              console.log("Form data sent successfully:", response.data);
              resetForm();
              setSubmitting(false);
              // setStatusMessage("Sent successfully!");
              handleNotification("Sent successfully!", "success");
            })
            .catch((error) => {
              console.error("There was an error sending the form data:", error);
              setSubmitting(false);
              // setStatusMessage("Failed to send. Please try again later.");
              handleNotification(
                "Failed to send. Please try again later.",
                "error"
              );
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-field">
              <label>Full Name</label>
              <Field type="text" name="fullName" />
              <ErrorMessage
                name="fullName"
                component="div"
                className="error-message"
              />
            </div>
            <div className="formCol">
              <div className="form-field">
                <label>Address</label>
                <Field type="text" name="address" />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="form-field">
                <label>City</label>
                <Field type="text" name="city" />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="formCol">
              <div className="form-field">
                <label>Province</label>
                <Field type="text" name="province" />
                <ErrorMessage
                  name="province"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="form-field">
                <label>Postal Code</label>
                <Field type="text" name="postalCode" />
                <ErrorMessage
                  name="postalCode"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="formCol">
              <div className="form-field">
                <label>Phone Number</label>
                <Field type="text" name="phoneNumber" />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="form-field">
                <label>Email Address</label>
                <Field type="email" name="emailAddress" />
                <ErrorMessage
                  name="emailAddress"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="form-field">
              <label>Do you have transportation?</label>
              <span>
                Hampers may need to be picked up by the individual requesting
                for the hamper or alternate designate
              </span>
              <Field type="checkbox" name="transportation" />
            </div>

            <div className="form-field">
              <label>Are you breastfeeding?</label>
              <span>
                this helps us determine if breastfeeding items are needed in the
                hamper
              </span>
              <Field type="checkbox" name="breastfeeding" />
            </div>

            <button
              className="btnPrimary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {/* <p className="alertMsg">{statusMessage}</p> */}
          </Form>
        )}
      </Formik>
      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default HamperForm;
