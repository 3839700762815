import React from "react";
import { Helmet } from "react-helmet";
import { homeContent } from "../../constants/data";

const HomePageMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const homeUrl = baseUrl;

  const metadata = {
    title: "Mesh of Mothers - Empowering African Canadian Mothers",
    description: homeContent.showcaseParagraph,
    keywords:
      "Mesh of Mothers, African Canadian mothers, Edmonton, Alberta, community support, empowerment, maternal well-being",
    og: {
      title: "Mesh of Mothers - Stronger Together",
      description: `${homeContent.showcaseHeader1} ${homeContent.showcaseHeader2}: ${homeContent.showcaseParagraph}`,
      image: `${baseUrl}/moms-logo.png`,
      url: homeUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Mesh of Mothers - Supporting African Canadian Mothers",
      description: homeContent.motheringP2,
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: "Mesh of Mothers Foundation Inc",
      url: homeUrl,
      logo: `${baseUrl}/moms-logo.png`,
      description: homeContent.showcaseParagraph,
      foundingDate: "2021",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Edmonton",
        addressRegion: "Alberta",
        addressCountry: "Canada",
      },
      sameAs: [
        "https://www.facebook.com/meshofmothers",
        "https://tiktok.com/meshofmothers",
        "https://www.instagram.com/meshofmothers",
        "https://www.youtube.com/@meshofmothers",
      ],
      slogan: homeContent.ceoSection.details[1],
      areaServed: {
        "@type": "City",
        name: "Edmonton",
      },
      founder: {
        "@type": "Person",
        name: "Millicent Asiedu",
      },
      memberOf: homeContent.partnerLogos.flat().map((partner) => ({
        "@type": "Organization",
        name: partner.alt,
      })),
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default HomePageMetadata;
