import React from "react";
import { MdWorkspacePremium, MdWork, MdLocationPin } from "react-icons/md";
import { FaGlobe, FaPhoneAlt } from "react-icons/fa";
import { BsFillClockFill, BsEnvelopeAtFill } from "react-icons/bs";
import { HiUser } from "react-icons/hi2";
import defaultPic from "../../assets/images/default-avatar.png";

const SelectedJob = ({ selectedJob, specialClassName }) => {
  return (
    <div className={`selected-job ${specialClassName}`}>
      <div class="sticky-contents">
        {selectedJob && (
          <div className="job-details">
            <div className="profile-avatar">
              <img
                src={selectedJob.avatar ? selectedJob.avatar : defaultPic}
                alt={`${selectedJob.Prefix} ${selectedJob.firstName} ${selectedJob.lastName}`}
                loading="lazy"
              />
            </div>
            <h2>{`${selectedJob.Prefix} ${selectedJob.firstName} ${selectedJob.lastName}`}</h2>
            {selectedJob.profession ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <MdWorkspacePremium className="icon" />
                </div>
                <div className="detail-info-item">
                  <p>{selectedJob.profession}</p>
                </div>
              </div>
            ) : null}
            {selectedJob.offerType ? (
              <div className="detail-info">
                {selectedJob.offerType && (
                  <div className="detail-info-item">
                    <BsFillClockFill className="icon" />
                  </div>
                )}
                <div className="detail-info-item">
                  <p>{selectedJob.offerType}</p>
                </div>
              </div>
            ) : null}

            {selectedJob.company ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <MdWork className="icon" />
                </div>
                <div className="detail-info-item">
                  <p>{selectedJob.company}</p>
                </div>
              </div>
            ) : null}
            {selectedJob.website ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <FaGlobe className="icon" />
                </div>
                <div className="detail-info-item">
                  <p>{selectedJob.website}</p>
                </div>
              </div>
            ) : null}
            {selectedJob.email ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <BsEnvelopeAtFill className="icon" />
                </div>
                <div className="detail-info-item">
                  <p>{selectedJob.email}</p>
                </div>
              </div>
            ) : null}

            {selectedJob.phoneNumber ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <FaPhoneAlt className="icon" />
                </div>
                <div className="detail-info-item">
                  <p>{selectedJob.phoneNumber}</p>
                </div>
              </div>
            ) : null}
            <hr className="line-light" />
            {selectedJob.bio ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <HiUser className="icon" />
                </div>

                <div className="detail-info-item">
                  <p>{selectedJob.bio}</p>
                </div>
              </div>
            ) : null}
            <hr className="line-light" />
            {selectedJob.address ? (
              <div className="detail-info">
                <div className="detail-info-item">
                  <MdLocationPin className="icon" />
                </div>
                <div className="detail-info-item">
                  <p>{selectedJob.address}</p>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedJob;
