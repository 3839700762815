import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./float_buttons.css";
import { FiSettings } from "react-icons/fi";
import { MdOutlineArticle, MdSlowMotionVideo } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";

const FloatButtons = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [empowermentVideo, setEmpowermentVideo] = useState();

  const handleHowToDonateClick = () => {
    navigate("/donate?tab=regular");
  };

  const checkOut = () => {
    console.log("checkOut");
  };

  return (
    <>
      <div className="float-buttons">
        <ul className="">
          <li>
            <button
              onClick={handleHowToDonateClick}
              className="btn btn-default video-btn d-flex align-items-center justify-content-between"
            >
              <span className="pe-2">how to donate</span>
              <span className="ms-1">
                <FaHandHoldingUsd size={20} />
              </span>
            </button>
          </li>
          <li className="">
            <Link
              to={"https://form.jotform.com/211587666897275"}
              target="_blank"
              className="btn btn-default request-btn-2 d-flex align-items-center justify-content-between "
            >
              <span className="">become a member</span>
              <span className="ms-1">
                <FiSettings size={20} className="rotating" />
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"https://form.jotform.com/232890162361050"}
              target="_blank"
              className="btn btn-default resume-btn-2 d-flex align-items-center  justify-content-between "
            >
              <span className="pe-2">BIPOC Form</span>
              <span className="ms-1" style={{ fontSize: "1rem" }}>
                <MdOutlineArticle size={20} />
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default FloatButtons;
