import { createBrowserRouter } from "react-router-dom";

import Layout from "../Layouts/Layout";
import EventDetailLayout from "../Layouts/EventDetailLayout";
import {
  AboutUs,
  ContactUs,
  CookiePolicy,
  DonatePage,
  Home,
  Membership,
  NotFoundPage,
  PrivacyPolicy,
  EventDetail,
  TermsAndConditions,
  Events,
  Programs,
  Rsvp,
  Resources,
  DonationSuccess,
} from "../pages";
import Register from "../pages/register";
import ComingEvents from "../pages/upcoming-events-detail";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Layout />
      </>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "donate", element: <DonatePage /> },
      { path: "donation-success", element: <DonationSuccess /> },
      { path: "resources", element: <Resources /> },
      { path: "register", element: <Register /> },
      { path: "programs", element: <Programs /> },
      { path: "membership", element: <Membership /> },
      {
        path: "events",
        element: <EventDetailLayout />,
        children: [
          { index: true, element: <Events /> },
          { path: ":eventSlug", element: <EventDetail /> },
          {
            path: "upcoming-events/:upcomingEventSlug",
            element: <ComingEvents />,
          },
          {
            path: "upcoming-events/rsvp/:upcomingEventSlug",
            element: <Rsvp />,
          },
        ],
      },
      { path: "*", element: <NotFoundPage /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "cookie-policy", element: <CookiePolicy /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> },
    ],
  },
]);

export default router;
