import React from "react";
import "./skeletons.css";

const EventCardSkeleton = () => {
  return (
    <section className="eventCard">
      <div className="header">
        <div className="skeleton-title"></div>
      </div>
      <div className="cardsWrapper">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="card skeleton-card">
            <div className="skeleton-date"></div>
            <div className="info">
              <div className="skeleton-image"></div>
              <div className="details">
                <div className="skeleton-title"></div>
                <div className="skeleton-text"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventCardSkeleton;
