import React from "react";
import "./skeletons.css";

const HomeSkeleton = () => {
  return (
    <div className="home-skeleton">
      <section className="showcase-skeleton pt-6">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="content">
                <div className="header">
                  <div className="skeleton-text-head"></div>
                  <div className="skeleton-text-head"></div>
                </div>
                <div className="txtContent">
                  <div className="skeleton-text-sub"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                </div>
                <div className="btnHolder">
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="skeleton-image"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="mothering-skeleton">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="skeleton-image"></div>
            </div>
            <div className="ver">
              <div className="content">
                <div className="header">
                  <div className="skeleton-text-head"></div>
                </div>
                <div className="txtContent">
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                </div>
                <div className="btnHolder">
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeSkeleton;
