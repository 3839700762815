import React from "react";
import "./skeletons.css";

const ProgramsSkeleton = () => {
  return (
    <>
      <section className="titleSection pt-6 pb-5">
        <div className="container pt-3">
          <div className="row">
            <div className="col-12 flex justify-center">
              <div className="skeleton-title"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="programList">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index} className="mb-6">
                    <div className="skeleton-text w-3/4 h-6 mb-3"></div>
                    <div className="skeleton-paragraph mb-2"></div>
                    <div className="skeleton-paragraph mb-2"></div>
                    <div className="skeleton-paragraph w-1/2"></div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgramsSkeleton;
