import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import useCountdown from "../../hooks/useCountDown";
import "./countdown.css";

const ExpiredNotice = () => {
  return <div className="counter-expired" />;
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="timerWrapper">
      <h1 className="timer-header">Launches in:</h1>
      <div className="show-counter">
        <div className="countDownItems">
          <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
        </div>
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
