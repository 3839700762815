import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./modal.css";

const Modal = ({ closeModal }) => {
  const form = useRef();

  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    province: "",
    education_level: "",
    description: "",
  };

  const [
    {
      first_name,
      last_name,
      email,
      phone_number,
      address,
      province,
      education_level,
      description,
    },
    setState,
  ] = useState(initialState);

  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(
      first_name,
      last_name,
      email,
      phone_number,
      address,
      province,
      education_level,
      description
    );

    emailjs
      .sendForm(
        process.env.REACT_APP_VOLUNTEER_SERVICE_ID,
        process.env.REACT_APP_VOLUNTEER_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_VOLUNTEER_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text, result.status);
          clearState();
          setStatusMessage("Application sent! We'll get back to you shortly.");
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            `${error.text} Couldn't send. Check your network connection`
          );
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="closeBtn">
            <button onClick={() => closeModal(false)}>X</button>
          </div>

          <div className="header">
            <h1>Fill the form below to become a volunteer</h1>
          </div>
          <div className="modalContent">
            <div className="form-wrapper">
              <form ref={form} onSubmit={sendEmail}>
                <div className="formGroup">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    required
                  />
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="formGroup">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                  />
                  <input
                    type="tel"
                    name="phone_number"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="formGroup">
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    required
                  />
                  <input
                    type="text"
                    name="province"
                    placeholder="Province"
                    required
                  />
                </div>
                <div className="formSingle">
                  <select name="education_level" id="education_level" required>
                    <option value="" disabled selected>
                      Level of education
                    </option>
                    <option value="Undergraduate">Undergraduate</option>
                    <option value="Masters">Masters</option>
                    <option value="PhD">PhD</option>
                    <option value="Diploma/Certificate">
                      Diploma/Certificate
                    </option>
                  </select>
                </div>
                {/* <div className="formGroup">
                <input
                  type="email"
                  name="school_email"
                  placeholder="School e-mail address"
                  required
                />
                <input
                  type="number"
                  name="graduation_year"
                  placeholder="Expected graduation year"
                  required
                />
              </div> */}
                <div className="description">
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="10"
                    placeholder="A brief description of yourself (100 word maximum)"
                    required
                  ></textarea>
                </div>

                <p className="alertMsg">{statusMessage}</p>

                <input
                  type="submit"
                  value="Send"
                  className="btnPrimary"
                  style={{ width: "100%" }}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
