import React from "react";
import "./skeletons.css";

const ContactUsSkeleton = () => {
  return (
    <>
      <section className="contactTop pt-6">
        <div className="row text-center justify-content-center pb-4 mt-2">
          <div className="col-12">
            <div
              className="skeleton-title"
              style={{ height: "40px", width: "200px", margin: "0 auto" }}
            ></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-12 pr-lg-0">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex flex-column socialContainer">
                  {[...Array(5)].map((_, index) => (
                    <div key={index} className="skeleton-icon mt-2 mb-3"></div>
                  ))}
                </div>
                <div className="contactFormContainer">
                  <div className="skeleton-subtitle mb-4"></div>
                  {[...Array(4)].map((_, index) => (
                    <div key={index} className="skeleton-input mb-3"></div>
                  ))}
                  <div className="d-flex justify-content-end">
                    <div className="skeleton-button"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-12 pl-lg-0">
              <div className="row">
                <div className="col-12 pr-3">
                  <div className="skeleton-subtitle mb-2 float-right"></div>
                </div>
              </div>
              <div className="skeleton-map"></div>
              <div className="row mt-2">
                <div className="col-12 pr-3">
                  <div className="text-right mb-2 mr-3">
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsSkeleton;
