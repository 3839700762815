import { Outlet } from "react-router-dom";
import { CTAButton, Footer, Navbar } from "../components";
import FloatButtons from "../ui/floatButtons";

function Layout() {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
      {/* <CTAButton
        text="BIPOC Professional Form"
        link="https://form.jotform.com/232890162361050"
      /> */}
      <FloatButtons />
    </div>
  );
}

export default Layout;
