import AsakoKando from "../../assets/images/bipoc/Mrs-Meghan-Stewart.png";
import MeganStewart from "../../assets/images/bipoc/Asma-Rahman.png";
import KaylaPringle from "../../assets/images/bipoc/Tanya-Opoku.png";
import TanyaOpoku from "../../assets/images/bipoc/Mx-Asako-Kando.png";
import NaiomiSohi from "../../assets/images/bipoc/Dr-Naiomi-Sohi.png";
import TamaraWilliams from "../../assets/images/bipoc/Miss-Kayla-Pringle.png";
import KameelaOsman from "../../assets/images/bipoc/Ms-Tamara-Williams.png";
import KadaneHeadley from "../../assets/images/bipoc/Mrs-Kameela-Osman.png";
import RheaBridge from "../../assets/images/bipoc/Ms-Kadane-Headley.png";
import NasimSwitzer from "../../assets/images/bipoc/Rhea-Bridge.png";
import ChantelMcEvoy from "../../assets/images/bipoc/Ms-Nasim-Switzer.png";
import CybilSchaffer from "../../assets/images/bipoc/Chantel-McEvoy.png";
import KirenSandhu from "../../assets/images/bipoc/Dr-Cybil-Schaffer.png";
import MelviaAgbekoOdemakpore from "../../assets/images/bipoc/Ms-Kiren-Sandhu.png";
import KarenaDang from "../../assets/images/bipoc/Alex-Thompson.png";
import AlexThompson from "../../assets/images/bipoc/Mrs-Aleya-Nailor.png";
import OlamideEgbewunmi from "../../assets/images/bipoc/Mrs-Karena-Dang.png";
import DeniseStacey from "../../assets/images/bipoc/Olamide-Egbewunmi.png";
import LauraSolis from "../../assets/images/bipoc/Mrs-Denise-Stacey.png";
import AsmaRahman from "../../assets/images/bipoc/Lakeziah-Bey.png";
import NeisauMaria from "../../assets/images/bipoc/Laura-Solis.png";
import CandaceHaughian from "../../assets/images/bipoc/Neisau-Maria..png";
import LakeziahBey from "../../assets/images/bipoc/yyi.png";
import AleyaNailor from "../../assets/images/bipoc/kjhjg.png";
import EmmyChahal from "../../assets/images/bipoc/kjk.png";
import SimoneMoniz from "../../assets/images/bipoc/kjkjk.png";

import AmyCommanda from "../../assets/images/bipoc/new/AmyCommanda.png";
import BhaktiShah from "../../assets/images/bipoc/new/BhaktiShah.png";
import CrystalJohn from "../../assets/images/bipoc/new/CrystalJohn.png";
import DrAilyaPatel from "../../assets/images/bipoc/new/DrAilyaPatel.png";
import DrGinaWong from "../../assets/images/bipoc/new/DrGinaWong.png";
import FernandaVillanueva from "../../assets/images/bipoc/new/FernandaVillanueva.png";
import HalahAlareeki from "../../assets/images/bipoc/new/HalahAlareeki.png";
import JoefinPeter from "../../assets/images/bipoc/new/JoefinPeter.png";
import KelseyLeroux from "../../assets/images/bipoc/new/KelseyLeroux.png";
import MariaCheung from "../../assets/images/bipoc/new/MariaCheung.png";
import MelodyCesar from "../../assets/images/bipoc/new/MelodyCesar.png";
import MissAnjaliPrajapati from "../../assets/images/bipoc/new/MissAnjaliPrajapati.png";
import MissChantalGray from "../../assets/images/bipoc/new/MissChantalGray.png";
import MissChantalGray2 from "../../assets/images/bipoc/new/MissChantalGray2.png";
import MrsKristyAnthony from "../../assets/images/bipoc/new/MrsKristyAnthony.png";
import MsAlyciaTwoBears from "../../assets/images/bipoc/new/MsAlyciaTwo Bears.png";
import MsAmyColley from "../../assets/images/bipoc/new/MsAmyColley.png";
import MsAshleyWhylly from "../../assets/images/bipoc/new/MsAshleyWhylly.png";
import MsCarmenSchultz from "../../assets/images/bipoc/new/MsCarmenSchultz.png";
import MsSundusJan from "../../assets/images/bipoc/new/MsSundusJan.png";
import MsTanujaGadre from "../../assets/images/bipoc/new/MsTanujaGadre.png";
import MurielleSimplice from "../../assets/images/bipoc/new/MurielleSimplice.png";
import PaschiaMayers from "../../assets/images/bipoc/new/PaschiaMayers.png";
import RashmiPrakash from "../../assets/images/bipoc/new/RashmiPrakash.png";
import RochenyAlexandre from "../../assets/images/bipoc/new/RochenyAlexandre.png";
import ShaniCharlesBelle from "../../assets/images/bipoc/new/ShaniCharles-Belle.png";
import SheraniSivakumar from "../../assets/images/bipoc/new/SheraniSivakumar.png";
import YichingChua from "../../assets/images/bipoc/new/YichingChua.png";
import YolandeFinnRN from "../../assets/images/bipoc/new/YolandeFinnRN.png";

export const categories = [
  "All",
  "Midwifery",
  "Lactation Specialist/Consultant",
  "Doulas",
  "Obstetrics/Gynecologists",
  "Developmental Screening And Testing",
  "Mental Health Therapists",
  "Massage Therapists",
  "Yoga Instructors",
  "Prenatal/Postnatal Fitness Coach",
  "Physiotherapists",
  "Social Workers",
  "Psychologist",
  "Naturopathic Specialist",
  "Acupuncturist",
  "Dietitian / Nutritionist",
  "Registered Nurse",
  "Autism Consultant",
];
export const jobs = [
  {
    Prefix: "",
    firstName: "Asma",
    lastName: "Rahman",
    profession: "Mobility for Pregnancy and Birth",
    company: "Ruh Doula Care",
    website: "www.ruhdoulacare.ca",
    offerType: "In-Person and Virtual",
    address: "Sarnia and Wonderland. London, Ontario",
    postal_zip_code: "N6G 4G5",
    email: "asma@ruhdoulacare.ca",
    phoneNumber: "(416) 219-0304",
    category: "Lactation Specialist/Consultant",
    bio: "Birth Doula \n Childbirth education \n Mobility assessments for pregnancy, birth and postpartum \nTraditional Postpartum Healing \nLactation support",
    avatar: AsmaRahman,
  },
  {
    Prefix: "Mrs",
    firstName: "Meghan",
    lastName: "Stewart",
    profession: "Mindfulness Teacher/Trainer",
    company: "Mindfulness Meghan",
    website: "www.mindfulnessmeghan.com",
    offerType: "Virtual",
    address: "21 Maplehurst Ave. Nepean, Ontario",
    postal_zip_code: "K2H5A3",
    email: "reachmindfulnessmeghan@gmail.com",
    phoneNumber: "(613) 867-1100",
    category: "Mental Health Therapists",
    bio: "Mindfulness Coaching (Certified Levels 1 - 3 with Unified Mindfulness) \n Mindfulness Teaching/Training (Certified Teacher/Trainer with Unified Mindfulness) \n Reiki (certified 1 & 2)",
    avatar: MeganStewart,
  },
  {
    Prefix: "",
    firstName: "Tanya",
    lastName: "Opoku",
    profession: "Mental Health Therapists",
    company: "Forward First Therapy Services",
    website: "www.forwardfirst.ca",
    offerType: "In-Person and Virtual",
    address: "105 Consumers dr. Whitby, Ontario",
    postal_zip_code: "L1N 1C4",
    email: "forwardfirsttherapy@gmail.com",
    phoneNumber: "(905) 410-2033",
    category: "Mental Health Therapists",
    bio: "Psychotherapy services for individuals couples and family",
    avatar: TanyaOpoku,
  },
  {
    Prefix: "Dr",
    firstName: "Naiomi",
    lastName: "Sohi",
    profession: "Prenatal/Postpartum Chiropractic",
    company: "South Surrey Chiropractic and Wellness",
    website: "www.instagram.com/whiterockdoc",
    offerType: "In-Person",
    address: "305 1656 Martin Drive, Surrey. BC",
    postal_zip_code: "V4A6E7",
    email: "drnaiomisohi@gmail.com",
    phoneNumber: "(604) 531-6446",
    category: "Prenatal/Postnatal Fitness Coach",
    bio: "Prenatal and Postpartum care for moms and their babies. Working on pelvic alignment, birth prep and recovery from birth.  Improving function of mothers through all stages of their child's development. Webster Certified, ICPA trained.",
    avatar: NaiomiSohi,
  },
  {
    Prefix: "Ms",
    firstName: "Kayla",
    lastName: "Pringle",
    profession: "Massage Therapist",
    company: "Massage with Kay",
    website: "",
    offerType: "In-Person",
    address: "9945 167 street. Edmonton, Alberta",
    postal_zip_code: "T5p0k5",
    email: "kpringlemassage@gmail.com",
    phoneNumber: "(587) 501-6226",
    category: "Massage Therapists",
    bio: "I am a Registered massage therapist, I provide Swedish, therapeutic, deep tissue, prenatal, manual lymphatic drainage, and sports massage.",
    avatar: KaylaPringle,
  },
  {
    Prefix: "Ms",
    firstName: "Tamara",
    lastName: "Williams",
    profession: "Osteopath",
    company: "Innergetic Bien-être Wellness",
    website: "www.innergetic.ca",
    offerType: "In-Person and Virtual",
    address: "6100 Transcanada highway. Pointe Claire, Québec \n Suite 105",
    postal_zip_code: "H9R1B9",
    email: "tamara@innergetic.ca",
    phoneNumber: "(438) 940-2622",
    category: "Prenatal/Postnatal Fitness Coach",
    bio: "Naturopathic Specialist \n Pelvic Floor Therapist \n Rebound Therapy (physical therapy) \n Neuro-Lingustic Programming",
    avatar: TamaraWilliams,
  },
  {
    Prefix: "Mrs",
    firstName: "Kameela",
    lastName: "Osman",
    profession: "Mental Health Therapists",
    company: "Elite Counselling & Consulting Services",
    website: "www.elitec-c.com",
    offerType: "In-Person and Virtual",
    address: "93 King St. w 201. Bowmanville, Ontario",
    postal_zip_code: "L1C 4J7",
    email: "kosman@elitec-c.com",
    phoneNumber: "(905) 419-3242",
    category: "Mental Health Therapists",
    bio: "Social Workers \n Perinatal therapy & couples therapy \n -MSW/RSW \n -Perinatal mental health certified through Postpartum support international \n -training in CBT, DBT, ACT, clinical hypnotherapy, Gottman method for couples therapy level 1& 2, sex therapy foundations, sex- pain &trauma training and more",
    avatar: KameelaOsman,
  },
  {
    Prefix: "Ms",
    firstName: "Kadane",
    lastName: "Headley",
    profession:
      "Child and Youth Care Practitioner , Child Life and Pediatric Psychosocial Care",
    company: "Resilient Roots",
    website: "",
    offerType: "In-Person and Virtual",
    address: "335 Palliser Way.  Saskatoon, SK",
    postal_zip_code: "S7L 4R4",
    email: "walttakadane@gmail.com",
    phoneNumber: "(306) 716-1789",
    category: "Yoga Instructors",
    bio: "As a Black mother and  Certified Child and Youth practitioner with and interest in perinatal mental health, my focus revolves around understanding and addressing the developmental needs of children and families. Armed with a master's degree in Child Life and Pediatric Psychosocial Care, my approach is rooted in a strength-based perspective. Collaborating seamlessly with other healthcare professionals, I strive to provide education and support to children and their caregivers during the perinatal period. Working within the framework of their daily lives, my goal is to reduce fear and anxiety, fostering an environment that promotes overall well-being.",
    avatar: KadaneHeadley,
  },
  {
    Prefix: "",
    firstName: "Rhea",
    lastName: "Bridge",
    profession: "Physiotherapists",
    company: "Bridge Counselling & Psychotherapy",
    website: "",
    offerType: "Virtual",
    address:
      "PO Box 99900 VZ 525 480 \n RPO Brittany Glen. Mississauga, Ontario",
    postal_zip_code: "L5M 0T6",
    email: "rhea@bridgepsychotherapy.ca",
    phoneNumber: "(647) 948-9539",
    category: "Physiotherapists",
    bio: "I provide psychotherapy for mother's along different stages of their journey. I work from a place of compassion and empathy and incorporate ACT, CBT and Narrative Therapy in my work. I am currently working towards the PMH-C",
    avatar: RheaBridge,
  },
  {
    Prefix: "Ms",
    firstName: "Nasim",
    lastName: "Switzer",
    profession: "Psychotherapists",
    company: "Sessions with Nasim",
    website:
      "www.psychologytoday.com/ca/therapists/nasim-switzer-edmonton-ab/1122875",
    offerType: "Virtual",
    address: "7121 South Terwillegar Drive Northwest. Edmonton. AB",
    postal_zip_code: "T6R 0R",
    email: "sessionswithnasim@gmail.com",
    phoneNumber: "(780) 977-9041",
    category: "Psychologist",
    bio: "I provide culturally informed and neurodiversity affirming support to parents and parents to be. I work from an attachment, somatic, and systemic lens. My passion lies in supporting parents through the transition to parenthood, interpersonal difficulties, low self-esteem, trauma etc. I often support parents who wish to process some of their own traumas that can get triggered in their parenting journey so that they can show up as a better version of themselves. Traumas include complex trauma, sexual assault, birth trauma, pregnancy loss, and more.",
    avatar: NasimSwitzer,
  },
  {
    Prefix: "",
    firstName: "Chantel",
    lastName: "McEvoy",
    profession: "Psychologist",
    company: "Reframed Psychological",
    website: "www.reframedpsychological.ca",
    offerType: "In-Person and Virtual",
    address: "6055 Andrews Way SW 207. Edmonton, Alberta",
    postal_zip_code: "T6W 3S9",
    email: "chantel@reframedpsychological.ca",
    phoneNumber: "(587) 415-6390",
    category: "Psychologist",
    bio: "Perinatal grief and loss Certification (CC-PGL), Birth and Reproductive Trauma Certification(CC-BRT), Zentangle and Flash Technique for Perinatal Populations, and Relational Cultural Therapy for Perinatal Populations.",
    avatar: ChantelMcEvoy,
  },
  {
    Prefix: "Dr",
    firstName: "Cybil",
    lastName: "Schaffer",
    profession: "Psychologist",
    company: "Schaffer Psychology Inc.",
    website: "www.psychologytoday.com/ca",
    offerType: "In-Person and Virtual",
    address: "Suite 103 - 7 Boulder Boulevard. Stony Plain, Alberta",
    postal_zip_code: "T7Z1V6",
    email: "cybil@schafferpsychology.ca",
    phoneNumber: "(780) 910-3420",
    category: "Psychologist",
    bio: "I have completed my two day training in peri-natal mental health and did an advanced care certification in it. I have yet to complete my exam so I can fully be a PHMC provider.",
    avatar: CybilSchaffer,
  },
  {
    Prefix: "Ms",
    firstName: "Kiren",
    lastName: "Sandhu",
    profession: "Psychotherapist",
    company: "Kiren Therapy",
    website: "www.kirentherapy.ca",
    offerType: "Virtual",
    address: "71b Victor Avenue. Toronto, ON",
    postal_zip_code: "M4k1a7",
    email: "kirentherapy@gmail.com",
    phoneNumber: "(416) 871-4904",
    category: "Mental Health Therapists",
    bio: "- counselling for pre and post natal support, infertility, birth trauma and infant loss and parenting \n - I have advanced training in Somatic Psychotherapy and Internal Family Systems-- In addition, to somatic and parts therapy I integrate from CBT, DCT and ACT \n - I have a certification in perinatal mental health from the Canadian Perinatal Mental Health Trainings \n - I have completed a certification in postpartum anxiety and depression through Postpartum Support International  \n - I also have lived experience with infertility  \n - I am registered with the Alberta College of Social Workers",
    avatar: KirenSandhu,
  },
  {
    Prefix: "Dr",
    firstName: "Melvia",
    lastName: "Agbeko Odemakpore",
    profession: "Health Coach",
    company: "VIA Wellness Solutions Inc.",
    website: "www.viawellnesssolutions.com",
    offerType: "Virtual",
    address: "17515 13 Ave SW. Edmonton, Alberta",
    postal_zip_code: "T6W2J8",
    email: "info@viawellnesssolutions.com",
    phoneNumber: "(825) 343-8639",
    category: "Naturopathic Specialist",
    bio: "Naturopathic Medicine (professional degree/designation) \n Focus- Gut, Hormonal, Stress, Mental Health, Weight \n Certification- Health/Life Coaching; Auricular Medicine; Biotherapeutic drainage, Reiki; Virtual Care",
    avatar: MelviaAgbekoOdemakpore,
  },
  {
    Prefix: "Ms",
    firstName: "Simone",
    lastName: "Moniz",
    profession: "Psychologist",
    company: "Pine Integrated Health Centre",
    website:
      "www.psychologytoday.com/ca/therapists/simone-moniz-edmonton-ab/720099",
    offerType: "In-Person and Virtual",
    address: "201 6720 104st. Edmonton, Alberta",
    postal_zip_code: "T8H0J1",
    email: "simone.moniz@pinehealth.ca",
    phoneNumber: "(587) 855-5598",
    category: "Psychologist",
    bio: "I am PMH-C from Postpartum Support International, supporting parents and families in their perinatal journey. I am honoured to support parents in all aspects of perinatal needs, but I have a passion to give support to folks in the perinatal grief and loss areas (ie: miscarriage, TFMR, birth trauma, stillbirth, infant loss) \n \n I work from a trauma informed lens, always valuing consent and empowering my clients to understand their needs. I use a client centred approach with modalities of EMDR, ACT, CBT, IFS, self-compassion to meet my clients where they are at.",
    avatar: SimoneMoniz,
  },
  {
    Prefix: "",
    firstName: "Emmy",
    lastName: "Chahal",
    profession: "Doulas",
    company: "",
    website: "www.emmychahal.com",
    offerType: "In-Person and Virtual",
    address: "PO Box 65 Cobble Hill PO. Cobble Hill, BC ",
    postal_zip_code: "V0R1L0",
    email: "emmy.chahal@gmail.com",
    phoneNumber: "(604) 202-3936",
    category: "Doulas",
    bio: "Continuum Doula Training (UK, 2022) prenatal, birth, postpartum doula \n Pre/Post Natal Yoga Teacher \n Bodyworker \n Spirit Baby Communication",
    avatar: EmmyChahal,
  },
  {
    Prefix: "Mrs",
    firstName: "Aleya",
    lastName: "Nailor",
    profession: "Mental Health Therapists",
    company: "Seasons Family Centre",
    website: "www.seasonsfamilycentre.ca/aleya-nailor",
    offerType: "In-Person and Virtual",
    address: "405-3960 Quadra street. Victoria, BC ",
    postal_zip_code: "V8X 4A3",
    email: "aleyal.rcc@gmail.com",
    phoneNumber: "(778) 977-1371",
    category: "Mental Health Therapists",
    bio: "Registered Clinical counsellor; CC-PMH (Canadian Certified Perinatal Mental Health) in progress as well as BRT (Birth and reproductive trauma)",
    avatar: AleyaNailor,
  },
  {
    Prefix: "",
    firstName: "Alex",
    lastName: "Thompson",
    profession: "Pelvic Floor Therapist",
    company: "Tidal Motion Physiotherapy",
    website: "www.tidalmotionphysio.ca",
    offerType: "In-Person and Virtual",
    address: "100-1620 Garnet Rd. Victoria, BC ",
    postal_zip_code: "V8P3C8",
    email: "alex@tidalmotionphysio.ca",
    phoneNumber: "(672) 974-7945",
    category: "Physiotherapists",
    bio: "Registered Physiotherapist MScPT \n Pelvic floor physiotherapy \n Orthopaedic physiotherapy",
    avatar: AlexThompson,
  },
  {
    Prefix: "Mrs",
    firstName: "Karena",
    lastName: "Dang",
    profession: "Doulas",
    company: "Bumps Upon a Time",
    website: "www.bumpsuponatime.com",
    offerType: "In-Person and Virtual",
    address: "14115 138 St. Edmonton, AB ",
    postal_zip_code: "T6V0L6",
    email: "bumpsuponatime@gmail.com",
    phoneNumber: "(780) 819-8831",
    category: "Doulas",
    bio: "Certified Labour/Birth Doula \n Certified Postpartum Doula \n Perinatal Educator \n Doula Instructor/Trainer",
    avatar: KarenaDang,
  },
  {
    Prefix: "",
    firstName: "Olamide",
    lastName: "Egbewunmi",
    profession: "Lactation Specialist/Consultant",
    company: "Prairie health group",
    website: "www.prairiehealthgroup.ca",
    offerType: "In-Person and Virtual",
    address: "14061 Victoria  Trail NW. Edmonton, AB ",
    postal_zip_code: "T5Y 0W2",
    email: "info@prairiehealthgroup.ca",
    phoneNumber: "(403) 679-2952",
    category: "Lactation Specialist/Consultant",
    bio: "Lactation  and breastfeeding  consults  by a board certified  lactation consultant  IBCLC",
    avatar: OlamideEgbewunmi,
  },
  {
    Prefix: "Mrs",
    firstName: "Denise",
    lastName: "Stacey",
    profession: "Psychologist",
    company: "Wynter Psychology/Infinite Path",
    website: "www.wynterpsychology.com",
    offerType: "In-Person and Virtual",
    address: "Suite 201 9835 104 Street. Fort Saskatchewan, AB",
    postal_zip_code: "T8L 2PE",
    email: "wyntercounselling@gmail.com",
    phoneNumber: "(780) 306-4204",
    category: "Psychologist",
    bio: "Provide therapeutic support for anxiety, depression, parenting and life transition. I work from a person centred lens drawing in modalities such as CBT, Solution Focused and Spiritually Augmented CBT. Also trained in EMDR and Level 1 Gottman.",
    avatar: DeniseStacey,
  },
  {
    Prefix: "",
    firstName: "Laura",
    lastName: "Solis",
    profession: "Midwifery",
    company: "Riverdale Community Midwives",
    website: "www.riverdalemidwives.com",
    offerType: "In-Person and Virtual",
    address: "48 River St. Toronto, ON",
    postal_zip_code: "M5A 3N9",
    email: "laurasolisz@gmail.com",
    phoneNumber: "(416) 574-4452",
    category: "Midwifery",
    bio: "Prenatal, birth, postpartum midwifery care. English and Spanish",
    avatar: LauraSolis,
  },
  {
    Prefix: "",
    firstName: "Neisau",
    lastName: "Maria",
    profession: "Mental Health Therapists",
    company: "Marigold Counselling & Consulting Inc.",
    website: "www.marigoldhelps.ca",
    offerType: "In-Person and Virtual",
    address: "16880 111 Avenue. Edmonton, Alberta",
    postal_zip_code: "T5S0H4",
    email: "contact@marigoldhelps.ca",
    phoneNumber: "(587) 778-7634",
    category: "Mental Health Therapists",
    bio: "EMDR, Narrative, CBT, coaching, system navigation and counselling",
    avatar: NeisauMaria,
  },
  {
    Prefix: "",
    firstName: "Candace",
    lastName: "Haughian",
    profession: "Acupuncturist",
    company: "Nest Integrative Wellness",
    website: "www.nestinwellness.com",
    offerType: "In-Person",
    address: "10608 124 Street NW. Edmonton, Alberta",
    postal_zip_code: "T5N 1S3",
    email: "candace@nestinwellness.com",
    phoneNumber: "(780) 760-6378",
    category: "Acupuncturist",
    bio: "Fertility acupuncture, pre and post IVF/IUI acupuncture, acupuncture for perinatal mental health, moxa for turning babies, induction acupuncture, postpartum acupuncture.",
    avatar: CandaceHaughian,
  },
  {
    Prefix: "",
    firstName: "Lakeziah",
    lastName: "Bey",
    profession: "Yoga Instructor,  Wholistic Wellness Coach",
    company: ``,
    website: "manestaystudio.as.me",
    offerType: "In-Person, Virtual",
    address:
      "Jasper Place Area. My address is given upon booking edmonton, ab, t5p1x9",
    postal_zip_code: "t5p1x9",
    email: "manestaystudio@gmail.com",
    phoneNumber: "(306) 596-4294",
    category: "Yoga Instructors",
    bio: `I offer 1:1 sessions and groups`,
    avatar: LakeziahBey,
  },
  {
    Prefix: "Mrs",
    firstName: " Shani",
    lastName: " Charles-Belle",
    profession: "Doulas",
    company: `Wild Birth| Sacred Birthkeeping services + Apothecary`,
    website: "www.wildbirthkeeping.com",
    offerType: "In-Person, Virtual",
    address: `104 Caithness St E Caledonia, ON, N3W 1C2`,
    postal_zip_code: ``,
    email: `shani.c.belle@gmail.com`,
    phoneNumber: `(905) 616-4830`,
    category: "Doulas",
    bio: `Mesoamerican Indigenous ceremonial birthkeeping practices 
-Prenatal and postpartum preparatory workshops
-birthing support person training workshop
-drop in Q&A group
-private session womb massage, womb steaming, “closing the bones”
-prenatal blessing way ceremony
-Mesoamericana Indigenous Apothecary services 

*Dona trained 
*Studied with Mayan elders and in Ecuador with Kichwa traditional 
midwives in sacred birthing rituals and plant medicine 
*Alexander Technique certi`,
    avatar: ShaniCharlesBelle,
  },
  {
    Prefix: "Miss",
    firstName: "  Chantal",
    lastName: " Gray",
    profession: "Social Worker",
    company: `Winrose Oasis Counselling Services`,
    website: `www.winroseoasiscounselling.com`,
    offerType: "In-Person, Virtual",
    address: `236 Pritchard Road
Hamilton, ON, L8W3P7
`,
    postal_zip_code: ``,
    email: `cgray@winroseoasiscounselling.com
`,
    phoneNumber: `(647) 560-3226
`,
    category: "Social Workers",
    bio: `Cognitive Behaviour Therapy, Trauma informed, EMDR, acceptance and
commitment Therapy, EFT
`,
    avatar: MissChantalGray,
  },
  {
    Prefix: "",
    firstName: "Paschia",
    lastName: "Mayers",
    profession: "Physiotherapists",
    company: `Nest Integrative Wellness`,
    website: `www.nestinwellness.com`,
    offerType: "In-Person, Virtual",
    address: `10608 124 Street NW
Edmonton, AB, T5N 1S3
`,
    postal_zip_code: ``,
    email: `paschia@nestinwellness.com
`,
    phoneNumber: `(780) 760-6378

`,
    category: "Physiotherapists",
    bio: `A Registered Canadian Certified  Counsellor (CCC) with a Master's degree in Counselling and a Canadian Certiified perinatal Mental Health provider
(CC-PMH). I integrate therapeutic techniques from in Emotion Focused
Therapy (EFT), Solution Focused, and Relational-Cultural Therapy.
`,
    avatar: PaschiaMayers,
  },
  {
    Prefix: "",
    firstName: "Yiching",
    lastName: "Chua",
    profession: "Mental Health Therapists / Social Worker",
    company: `Tandem Waves Counselling Collective`,
    website: `www.tandemwavescollective.com`,
    offerType: "In-Person, Virtual",
    address: `154 James Street
St. Catharines , ON, L2R 5C4
`,
    postal_zip_code: ``,
    email: `tandemwavescollective@gmail.com

`,
    phoneNumber: `(905) 380-2366

`,
    category: "Mental Health Therapists",
    bio: `Cognitive Behavioural therapy (CBT), Dialectical Behavioural therapy,
Solution Focused therapy, Emotion Focused therapy, Mindfulness, and
Culturally Adapted CBT
`,
    avatar: YichingChua,
  },
  {
    Prefix: "",
    firstName: "Halah",
    lastName: "Alareeki",
    profession: "Dietitian / Nutritionist",
    company: `inara Nutrition and Nest Integrative Wellness`,
    website: `https://nestinwellness.janeapp.com/#/nutrition
`,
    offerType: "In-Person, Virtual",
    address: `10608 124 Street NW
Edmonton, Alberta, T5N 1S3
`,
    postal_zip_code: ``,
    email: `halahnutrition@gmail.com

`,
    phoneNumber: `(780) 760-6378

`,
    category: "Dietitian / Nutritionist",
    bio: `Nutrition care is provided in Arabic and English and can be in person in Edmonton or virtually to anyone in Alberta. Nutrition support areas may
include: Preconception, pregnancy and postpartum nutrition as well as,
Diabetes, hypertension, heart health, and pediatrics.
`,
    avatar: HalahAlareeki,
  },
  {
    Prefix: "",
    firstName: "Kelsey",
    lastName: " Leroux",
    profession: "Doulas / Child and youth care practitioner",
    company: `Postpartum and Beyond`,
    website: `Postpartumandbeyond.ca
`,
    offerType: "In-Person, Virtual",
    address: `182 Franklin ave
Toronto, Ontario, M6p3y8
`,
    postal_zip_code: ``,
    email: `kelsey.leroux@hotmail.com

`,
    phoneNumber: `(416) 895-5154

`,
    category: "Doulas",
    bio: `Postpartum doula care, consultation as child and youth care practitioner,
and childhood sexual abuse prevention, education, and awareness
advocacy.
`,
    avatar: KelseyLeroux,
  },
  {
    Prefix: "Miss",
    firstName: " Anjali ",
    lastName: "Prajapati",
    profession: "Physiotherapists",
    company: `Shakti Psychotherapy & Wellness`,
    website: `www.shaktipsychotherapy.ca
`,
    offerType: "In-Person, Virtual",
    address: `1053 Carling Ave
Ottawa, Ontario, K1Y 4E9
`,
    postal_zip_code: ``,
    email: `info@shaktipsychotherapy.ca

`,
    phoneNumber: `(613) 897-3587

`,
    category: "Physiotherapists",
    bio: `- I provide psychotherapy & counselling
- I work from a holistic and client centred framework
- I used trauma informed modalities like somatic healing, the polyvagal
theory, dbt, narrative therapy & creative/expressive arts
`,
    avatar: MissAnjaliPrajapati,
  },
  {
    Prefix: "",
    firstName: " Melody ",
    lastName: "Cesar",
    profession: "Prenatal/Postnatal Fitness Coach / Social Worker",
    company: `Tulia Counselling & Consulting`,
    website: `www.tuliayeg.com
`,
    offerType: "In-Person, Virtual",
    address: `210, 1524 91 St SW
Edmonton, Alberta, T6X 1M5

`,
    postal_zip_code: ``,
    email: `hello@tuliayeg.com

`,
    phoneNumber: `(587) 603-6132

`,
    category: "Prenatal/Postnatal Fitness Coach",
    bio: `-Perinatal Mental Health and Parental/Maternal Mental Health
-Grief and Loss
-Parenting in and after the NICU
Modalities include: EMDR, DBT, CBT, and Somatic and Attachment based
therapies.
`,
    avatar: MelodyCesar,
  },
  {
    Prefix: "",
    firstName: " Maria ",
    lastName: "Cheung",
    profession:
      "Yoga Instructors / PELVIC FLOOR THERAPISTS / Sexologist & Sexual Yoga Specialist",
    company: `Sage Holistic Healing`,
    website: `www.sageholistichealing.com
`,
    offerType: "In-Person, Virtual",
    address: `9 Irvington Crescent
Toronto, Ontario, M2N 2Y8

`,
    postal_zip_code: ``,
    email: `maria@sageholistichealing.com

`,
    phoneNumber: `(647) 278-9926

`,
    category: "Yoga Instructors",
    bio: `American Board Certified Sexologist (ID:23917)
    - Navigate sexual satisfaction before during and after birth
- Prepare for birth while enjoying more controlled and reliable orgasms
through pelvic floor exercises with yoni eggs
- Relieve pain/vaginismus during sex, especially post-natal
- Relearn orgasmic control after childbirth
- Navigate desirability during pregnancy and post pregnancy (especially
body image and acceptance through transitions)
Modalities include:
- Trauma-informed, holistic and somatic therapies
- Non-violent communication (with self and with partner)
- Authentic Tantra (R): From the only gov accredited sexual yogic school in
the world
- Meditation & mindful self-pleasure
- Sexual Anatomy & Pleasure Science
`,
    avatar: MariaCheung,
  },
  {
    Prefix: "",
    firstName: " Murielle ",
    lastName: " Simplice",
    profession: "YOGA INSTRUCTORS / PSYCHOTHERAPISTS / Registered Nurse",
    company: `Murielle Simplice Wholeness`,
    website: `www.muriellesimplice.com
`,
    offerType: "In-Person, Virtual",
    address: `13 Cynthia Crt
Nepean, Ontario, K2J2R9

`,
    postal_zip_code: ``,
    email: `murielle@muriellesimplice.com

`,
    phoneNumber: `(613) 257-9609

`,
    category: "Registered Nurse",
    bio: `Services:
Psychotherapy, mental health counselling, education and support for areas
of birth and reproductive trauma such as Fertility, loss, pregnancy,
termination, birth, feeding, postpartum, NICU and more.
Yoga and meditation

Certifications:
Registered Nurse, licensed to practice Psychotherapy
Birth and Reproductive Trauma Certification
200Hr yoga teacher certification
25hr yin yoga certification
`,
    avatar: MurielleSimplice,
  },
  {
    Prefix: "Ms",
    firstName: " Alycia ",
    lastName: " Two Bears",
    profession: "DOULAS / YOGA INSTRUCTORS",
    company: `Good Woman Medicine`,
    website: `www.goodwomanmedicine.com
`,
    offerType: "In-Person",
    address: `51 Mt Allan Circle SE
Calgary, Alberta , T2Z 2S3

`,
    postal_zip_code: ``,
    email: `goodwomanmedicine@gmail.com

`,
    phoneNumber: `(587) 968-6132

`,
    category: "Doulas",
    bio: `
`,
    avatar: MsAlyciaTwoBears,
  },
  {
    Prefix: "Ms",
    firstName: "  Tanuja ",
    lastName: " Gadre",
    profession: "Mental Health Therapists",
    company: `Tanuja Gadre Counselling`,
    website: `www.tanujagadre.com
`,
    offerType: "Virtual",
    address: `8800 Hazelbridge Way
Richmond , BC, V6X 0S3

`,
    postal_zip_code: ``,
    email: `counselortanuja@gmail.com
`,
    phoneNumber: `(236) 889-5680
`,
    category: "Mental Health Therapists",
    bio: `Trauma informed counselling. I use a strength based approach. Culturally
sensitive counseling provided. I use tools and techniques from CBT DBT
Narrative, Mindfulness, Somatic approaches, Gottman Method Couples
Therapy.
I have training in DBT, am training in Somatic Experiencing and Gottman
Method Couples Therapy.

`,
    avatar: MsTanujaGadre,
  },
  {
    Prefix: "Dr",
    firstName: "  Ailya",
    lastName: " Patel",
    profession: "Lactation Specialist/Consultant / Naturopathic Specialist",
    company: `Tanuja Gadre Counselling`,
    website: `
`,
    offerType: "Virtual",
    address: `10 Linsmary court
Markham , On, L6E 1L2

`,
    postal_zip_code: ``,
    email: `drailyap@gmail.com
`,
    phoneNumber: `(647) 505-6295
`,
    category: "Lactation Specialist/Consultant",
    bio: `I am trained as a naturopathic doctor and part of my training involved
mental health support in the form of counselling and
supplements/herbs/acupuncture which may also be required as
complementary therapy to those who are already medicated, or those that
need the support and not on medication.

`,
    avatar: DrAilyaPatel,
  },
  {
    Prefix: "Miss",
    firstName: "Chantal ",
    lastName: " Gray",
    profession: "Mental Health Therapists / Physiotherapists / Social Workers",
    company: `Winrose Oasis Counselling Services`,
    website: `www.winroseoasiscounselling.com
`,
    offerType: "In-Person, Virtual",
    address: `236 Pritchard Road
Hamilton , ON, L8W3P7
`,
    postal_zip_code: ``,
    email: `info@winroseoasiscounselling.com
`,
    phoneNumber: `(647) 360-3226
`,
    category: "Mental Health Therapists",
    bio: `Services: Family counselling, parental support
Modalities/ certification: EMDR, CBT, SNAP program, DBT, ACT, Play
therapy, and trauma informed


`,
    avatar: MissChantalGray2,
  },
  {
    Prefix: "",
    firstName: "Rashmi ",
    lastName: "Prakash",
    profession: `Medical device research and development focused on maternal and fetal health`,
    company: `University of British Columbia`,
    website: `https://bme.ubc.ca/?directory=rashmi-prakash
`,
    offerType: " Virtual",
    address: `School of Biomedical Engineering
Vancouver, BC, V6T 1Z3
`,
    postal_zip_code: ``,
    email: `rashmi.prakash@ubc.ca
`,
    phoneNumber: `(778) 776-7890
`,
    category: "Doulas",
    bio: `We provide evidence based guidance for the development of technologies
and policies that support the consideration and improvement for perinatal
mental health

`,
    avatar: RashmiPrakash,
  },
  {
    Prefix: "",
    firstName: "Fernanda ",
    lastName: " Villanueva",
    profession: `Mental Health Therapists`,
    company: `Bloom Well Therapy`,
    website: `www.bloomwelltherapy.ca
`,
    offerType: " Virtual",
    address: `720 Spadina Ave
Toronto, Ontario, M5S 2T9
`,
    postal_zip_code: ``,
    email: `hello@bloomwelltherapy.ca
`,
    phoneNumber: `(416) 804-8605
`,
    category: "Mental Health Therapists",
    bio: `The Bloom Well Therapy team is a culturally diverse team of therapists that offer therapy related to infertility, pregnancy, birth, postpartum and beyond. All therapists have specialized training in this area. We also offer therapy services ranging from CBT, mindfulness, EMDR and other
modalities

`,
    avatar: FernandaVillanueva,
  },
  {
    Prefix: "Ms",
    firstName: "Carmen ",
    lastName: "  Schultz",
    profession: `Prenatal Instructor/Childbirth Educator`,
    company: `Agassiz Chilliwack Prenatal`,
    website: `www.agassizchilliwackprenatal.com
`,
    offerType: " Virtual",
    address: `6837 Lougheed Highway
Agassiz , BC, V0M1A0
`,
    postal_zip_code: ``,
    email: `valleydoula@hotmail.com
`,
    phoneNumber: `(604) 701-2511
`,
    category: "Prenatal/Postnatal Fitness Coach",
    bio: `As a certified  Childbirth Educator with over two decades of experience, I offer private personalized prenatal education for expectant families virtually throughout BC. I also offer  in person group classes within the District of Kent.

`,
    avatar: MsCarmenSchultz,
  },
  {
    Prefix: "",
    firstName: "Rocheny ",
    lastName: "   Alexandre",
    profession: `Physiotherapists / Social Worker`,
    company: `VRAI Health & Wellness / VRAI santé & Bien-être`,
    website: `www.vraiwellness.com
`,
    offerType: " In-Person, Virtual",
    address: `16 newbridge Rd
Etobicoke, ON, M8Z 2L7
`,
    postal_zip_code: ``,
    email: `info@vraiwellness.com
`,
    phoneNumber: `(647) 646-8724
`,
    category: "Physiotherapists",
    bio: `Culturally sensitive, CBT, DBT, ACT Behavioural consult and coaching
`,
    avatar: RochenyAlexandre,
  },
  {
    Prefix: "Dr",
    firstName: "Gina ",
    lastName: "Wong",
    profession: `Psychologist`,
    company: `Dr. Gina Wong, Counselling & Consulting`,
    website: `http://linkedin.com/in/dr-gina--wong-psychologist
`,
    offerType: " In-Person, Virtual",
    address: `7203 Saskatchewan Drive
Edmonton, Alberta, T6G 2A4
`,
    postal_zip_code: ``,
    email: `ginapsychologist@gmail.com
`,
    phoneNumber: `(866) 266-4178
`,
    category: "Psychologist",
    bio: `Registered Psychologist in Alberta, specializing in reproductive and
perinatal mental health for over 20 years. Perinatal Mental Health
Certification through Postpartum Support International and serving
Indigenous, Black, Asian, People of Colour clinically during the childbearing
and childbearing phases of life. Circle of Security, Level II registered
facilitator and a Perinatal and Infant Mental Health Supervisor and
Consultant https://perinataltrainingcentre.com.au/team/dr-gina-wong
`,
    avatar: DrGinaWong,
  },
  {
    Prefix: "Ms",
    firstName: " Sundus ",
    lastName: "Jan",
    profession: `PSYCHOTHERAPISTS`,
    company: `YOU ARE HOPE THERAPY`,
    website: `
`,
    offerType: " ",
    address: `Nepean, Nepean
Ottawa, Ontario, K2g 6A6

`,
    postal_zip_code: ``,
    email: `sundusjanpsychotherapy@gmail.com
`,
    phoneNumber: `(343) 882-8215
`,
    category: "Psychologist",
    bio: `Trained in:
Eye Movement Desensitization Reprocessing
Cognitive Behavioural Therapy
Dialectical Behaviour Therapy
Also utilize Internal Family systems and Trauma Tri-phasic Model
approaches
`,
    avatar: MsSundusJan,
  },
  {
    Prefix: "",
    firstName: " Joefin ",
    lastName: " Peter",
    profession: `Doulas / Sexual Health Educator`,
    company: `10 o'clock joefi`,
    website: `https://sites.google.com/view/10oclockjoefi/home
`,
    offerType: "In-Person, Virtual ",
    address: `14937 93A Avenue
Surrey, British Columbia, V3R 7A8

`,
    postal_zip_code: ``,
    email: `joefin.m.peter@gmail.com
`,
    phoneNumber: `(604) 726-7992
`,
    category: "Doulas",
    bio: `Full-Spectrum Doula through the Doula Training Canada (fertility/loss,
pregnancy, labour/birth, postpartum/perinatal); Sexual Health Educator
through Options BC; First Aid & CPR-C with Lifesaving Society BC; Mental
Health First Aid
`,
    avatar: JoefinPeter,
  },
  {
    Prefix: "",
    firstName: " Amy ",
    lastName: " Commanda",
    profession: `PSYCHOTHERAPISTS`,
    company: `Walk In Beauty Counselling`,
    website: `www.walkinbeautycounselling.ca
`,
    offerType: " Virtual ",
    address: `325 Marttila Drive
Sudbury, Ontario, P3E 2K6

`,
    postal_zip_code: ``,
    email: `walkinbeautycounselling@gmail.com
`,
    phoneNumber: `(249) 487-0865
`,
    category: "Psychologist",
    bio: `Psychotherapy services with a Relational/CBT/DBT/ACT/Mindfulness
approach. Training through PSI in Components of Care and Advanced
Psychotherapy techniques.

`,
    avatar: AmyCommanda,
  },
  {
    Prefix: "Mrs",
    firstName: " Kristy ",
    lastName: " Anthony",
    profession: `Perinatal Mental Health Peer Support Worker`,
    company: `Families Matter`,
    website: `www.familiesmatter.ca
`,
    offerType: " In-Person, Virtual ",
    address: `3404 25 Street N.E.
Calgary, Alberta, T1Y 6C1

`,
    postal_zip_code: ``,
    email: `kristy.anthony@familiesmatter.ca
`,
    phoneNumber: `(403) 880-5732
`,
    category: "Mental Health Therapists",
    bio: `* Perinatal Mental Health (PMH) 1-on-1 Peer Support for
African/Caribbean/Black (ACB) Canadians. (Phone)
* On-going Postpartum Depression (PPD) Support Groups for ACB
Canadians. "Melanin Mama Circle" (Virtual)
* PPD Info Sessions for ACB Couples. (Virtual)
* Monthly Mental Health Conversational and Networking group for ACB
community. "The CHAT" (Virtual)
* PMH Community Events for ACB Canadians, that can include healing
techniques, Perinatal Mood Disorder (PMD) psychoeducation information
(de

`,
    avatar: MrsKristyAnthony,
  },
  {
    Prefix: "",
    firstName: " Crystal ",
    lastName: " John",
    profession: `DOULAS / MENTAL HEALTH THERAPISTS /
PSYCHOTHERAPISTS / SOCIAL WORKERS
`,
    company: `EbonyDoula`,
    website: `
`,
    offerType: " Virtual ",
    address: `36 Beaver Crescent
Dartmouth, NS, B2V1C9

`,
    postal_zip_code: ``,
    email: `Ebonydoula64@gmail.com
`,
    phoneNumber: `(902) 483-3101
`,
    category: "Doulas",
    bio: `I use CBT in my work with a specialization in working with African
Nova Scotian women and Intergenerational trauma

`,
    avatar: CrystalJohn,
  },
  {
    Prefix: "",
    firstName: " Bhakti ",
    lastName: " Shah",
    profession: `MENTAL HEALTH THERAPISTS /
PSYCHOTHERAPISTS / SOCIAL WORKERS
`,
    company: `Ekya Counselling & Psychotherapy Services`,
    website: `
`,
    offerType: " Virtual ",
    address: `5 Amaranth Crescent
Brampton , ON, L7A0L7

`,
    postal_zip_code: ``,
    email: `bhakti@ekyapsychotherapy.ca
`,
    phoneNumber: `(289) 302-6906
`,
    category: "Mental Health Therapists",
    bio: `Perinatal Mental Health Counselling
Maternal Mental Load Counselling
Parent Counselling & Coaching (birth to 12 years)
Modalities: CBT, SFBT, Sensorimotor Psychotherapy l, Attachment-based,
Trauma-informed, Feminist
Certifications: : Attachment Parenting Parent Education, Sensorimotor
Psychotherapy Level 1


`,
    avatar: BhaktiShah,
  },
  {
    Prefix: "",
    firstName: " Sherani ",
    lastName: " Sivakumar",
    profession: `Psychologist
`,
    company: `BIPOC Healing and Wellness Centre`,
    website: `https://www.bipochealingcentre.ca/team/sheranisivakumar
`,
    offerType: "",
    address: `8540 109 St NW, #401
Edmonton, Alberta, T6G 1E6


`,
    postal_zip_code: ``,
    email: `sherani@bipochealingcentre.ca
`,
    phoneNumber: `(587) 760-1311
`,
    category: "Psychologist",
    bio: `-Perinatal Struggles (Incl. Adjustment, Grief and loss, relationship changes,
family dynamics).
-Perinatal Mood & Anxiety Disorders
Certifications/Modalities:
Perinatal Mood Disorders: Components of Care – 2023
- Narrative Therapy
- Acceptance and Commitment Therapy
- Somatic-Based Therapy (Hakomi)
- Interpersonal Therapy

`,
    avatar: SheraniSivakumar,
  },
  {
    Prefix: "Ms",
    firstName: " Amy ",
    lastName: " Colley",
    profession: `Autism Consultant
`,
    company: `Amy Colley`,
    website: `www.amycolley.com

`,
    offerType: " Virtual ",
    address: `2208 Av. Regent
Montréal, QC, H4A 2R1


`,
    postal_zip_code: ``,
    email: `info@amycolley.com
`,
    phoneNumber: `(514) 710-2207
`,
    category: "Autism Consultant",
    bio: `Amy Colley provides parents of autistic children aged 18 and under online
support and tools to help manage their child's behavior at home.


`,
    avatar: MsAmyColley,
  },
  {
    Prefix: "",
    firstName: " Yolande ",
    lastName: " Finn RN",
    profession: `Prenatal Classes
`,
    company: `ClassWomb Prenatal Education`,
    website: `www.classwomb.ca

`,
    offerType: " In-Person ",
    address: `Client’s Residence
St. Catharines, On, L2T3K7

`,
    postal_zip_code: ``,
    email: `yfinn@classwomb.ca
`,
    phoneNumber: `(416) 574-9292
`,
    category: "Prenatal/Postnatal Fitness Coach",
    bio: `Prenatal classes which includes topics such as postpartum mood disorders, self care and where to find  community mental health supports.



`,
    avatar: YolandeFinnRN,
  },
  {
    Prefix: "Ms",
    firstName: "  Ashley ",
    lastName: " Whylly",
    profession: `PSYCHOTHERAPISTS
`,
    company: `Whylly Wellness Psychotherapy`,
    website: `https://www.psychologytoday.com/ca/therapists/ashley-d-whylly-toronto-on/1311528

`,
    offerType: " Virtual ",
    address: `Avenue Road
Toronto, Ontario, M5N 2G3


`,
    postal_zip_code: ``,
    email: `adwhylly@gmail.com
`,
    phoneNumber: `(289) 512-2633
`,
    category: "Psychologist",
    bio: `My goal is to support parents in the reproductive stages of life. I offer perinatal and postpartum support, and couples support for new parents.
My primary modalities are Relation-Cultural Therapy and Emotion-Focused
Therapy. My approach is anti-oppressive, trauma-informed, and strength-focused.


`,
    avatar: MsAshleyWhylly,
  },
];
