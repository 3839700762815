import React from "react";
import "./skeletons.css";

const DonatePageSkeleton = () => {
  return (
    <>
      <div className="container pt-6">
        <div className="skeleton-tabs">
          <div className="skeleton-tab"></div>
          <div className="skeleton-tab"></div>
        </div>
      </div>

      <section className="supportSection pt-2">
        <div className="container pt-4">
          <div className="skeleton-title"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
        </div>
      </section>

      <section className="paymentMethodList">
        <div className="container">
          <div className="skeleton-subtitle"></div>
          <div className="skeleton-list">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="skeleton-list-item"></div>
            ))}
          </div>
        </div>
      </section>

      <section className="paymentProcess my-3">
        <div className="container">
          <div className="skeleton-subtitle"></div>
          <div className="row mt-5 pt-lg-3 pt-5 card-group">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="col-lg-6 col-12 mb-4">
                <div className="skeleton-card">
                  <div className="skeleton-card-icon"></div>
                  <div className="skeleton-card-title"></div>
                  <div className="skeleton-card-text"></div>
                  <div className="skeleton-card-text"></div>
                  <div className="skeleton-card-text"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="stickerSection">
        <div className="container py-5 mb-4">
          <div className="skeleton-image"></div>
        </div>
      </section>
    </>
  );
};

export default DonatePageSkeleton;
