import React from "react";
import { Helmet } from "react-helmet";
import { event } from "../../constants/data";

const EventsPageMetadata = () => {
  const baseUrl = "https://meshofmothers.ca";
  const eventsUrl = `${baseUrl}/events`;

  const eventTitles = event.map((e) => e.title).join(", ");
  const eventLocations = [...new Set(event.map((e) => e.location))].join(", ");

  const metadata = {
    title: "Events | Mesh of Mothers",
    description:
      "Explore Mesh of Mothers' events supporting African Canadian mothers in Edmonton. Join us for psycho-education, self-care, fitness, and community support programs.",
    keywords: `Mesh of Mothers, events, ${eventTitles}, ${eventLocations}, African Canadian mothers, community support`,
    og: {
      title: "Mesh of Mothers Events - Empowering African Canadian Mothers",
      description:
        "Discover Mesh of Mothers' events in Edmonton. From psycho-education to fitness programs, find opportunities to connect and grow with other African Canadian mothers.",
      image: `${baseUrl}/moms-logo.png`,
      url: eventsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Mesh of Mothers Event Calendar",
      description:
        "Stay updated with Mesh of Mothers' events. Join our community-focused initiatives supporting African Canadian mothers in Edmonton.",
      image: `${baseUrl}/moms-logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      name: "Mesh of Mothers Events",
      description:
        "A listing of past and upcoming events organized by Mesh of Mothers, focusing on supporting African Canadian mothers in Edmonton through various programs and initiatives.",
      url: eventsUrl,
      publisher: {
        "@type": "Organization",
        name: "Mesh of Mothers Foundation Inc",
        url: baseUrl,
      },
      mainEntity: {
        "@type": "ItemList",
        itemListElement: event.map((e, index) => ({
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@type": "Event",
            name: e.title,
            description: e.details,
            location: {
              "@type": "Place",
              name: e.location,
            },
            startDate: `${e.date.year}-${e.date.month.padStart(
              2,
              "0"
            )}-${e.date.day.padStart(2, "0")}`,
          },
        })),
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default EventsPageMetadata;
