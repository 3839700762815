import React from "react";
import "./stripe.css";
import DonatePayment from "../donate-payment";
import DonationWrapper from "./DonationWrapper";

const StripePayment = () => {
  return (
    <section className="stripePayment my-5">
      <div className="container">
        <p style={{ marginBottom: "1rem" }}>
          Please complete your payment details below to finalize your donation.
          We thank you for your kind gesture. Your information is
          safe and secure.
        </p>
        <div className="row" style={{ marginBottom: "1rem" }}>
          <DonatePayment />
        </div>
        <DonationWrapper />
      </div>
    </section>
  );
};

export default StripePayment;
