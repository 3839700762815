import React from "react";
import "./skeletons.css";

const EventDetailSkeleton = () => {
  return (
    <section className="eventDetailSection">
      <div className="container">
        <div className="eventHeader hor">
          <div className="left ver flex">
            <div className="eventTitle">
              <div className="skeleton-title"></div>
            </div>
          </div>
          <div className="right ver flex">
            <div className="imgWrapper">
              <div className="skeleton-image" style={{ width: "100%" }}></div>
            </div>
          </div>
        </div>
        <div className="eventDetail">
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
        </div>
        <div className="eventVideo">
          <div className="skeleton-video"></div>
        </div>
        <div className="eventPictures">
          <div className="header">
            <div className="skeleton-subtitle"></div>
          </div>
          <div className="skeleton-gallery">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="skeleton-gallery-item"></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventDetailSkeleton;
